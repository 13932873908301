import invariant from 'tiny-invariant'
import { ChainId } from '../constants'
import { validateAndParseAddress } from '../utils'
import { Currency } from './currency'

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends Currency {
  public readonly chainId: ChainId
  public readonly address: string

  public constructor(chainId: ChainId, address: string, decimals: number, symbol?: string, name?: string) {
    super(decimals, symbol, name)
    this.chainId = chainId
    this.address = validateAndParseAddress(address)
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Token): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }
    return this.chainId === other.chainId && this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB)
  } else if (currencyA instanceof Token) {
    return false
  } else if (currencyB instanceof Token) {
    return false
  } else {
    return currencyA === currencyB
  }
}

export const WBTC = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Sherpax'
  ),
  [ChainId.ROPSTEN]: new Token(
    ChainId.ROPSTEN,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WETH',
    'Wrapped Sherpax'
  ),
  [ChainId.RINKEBY]: new Token(
    ChainId.RINKEBY,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WETH',
    'Wrapped Sherpax'
  ),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6', 18, 'WETH', 'Wrapped Sherpax'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0xd0A1E359811322d97991E03f863a0C30C2cF029C', 18, 'WETH', 'Wrapped Sherpax'),
  [ChainId.MATIC]: new Token(ChainId.MATIC, '0x6817c8475Ad33Aa86422160C3d1C673c453A76dE', 18, 'WETH', 'Wrapped Sherpax'),
  // [ChainId.SHERPAX]: new Token(ChainId.SHERPAX, '0x900639Cc5A37c519C9E32Bfa7EAdf747c53D9B0A', 18, 'WKSX', 'Wrapped Sherpax'),
  // [ChainId.SHERPAXTEST]: new Token(ChainId.SHERPAXTEST, '0xDf62d18AFA1A804601b72d49aDC6Ed4fb336a672', 18, 'WKSX', 'Wrapped Sherpax'),
  [ChainId.BSC]: new Token(ChainId.BSC, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB'),
  [ChainId.BSCTEST]: new Token(ChainId.BSCTEST, '0x5b3e2bc1da86ff6235d9ead4504d598cae77dbcb', 18, 'WBNB', 'Wrapped BNB (Test Net)'),
  [ChainId.BEVMTEST]: new Token(ChainId.BEVMTEST, '0xFd861b4eeA652d92a679504a8b8341655340eA5d', 8, 'WPCX', 'Wrapped chainx'),
  [ChainId.CHAINX]: new Token(ChainId.CHAINX, '0x09Ff8E49D0EA411A3422ed95E8f5497D4241F532', 18, 'WBTC', 'Wrapped BTC'),

}
